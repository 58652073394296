*
{
    margin: 0;
    padding: 0;
}

html {
    background: black;
    overflow: scroll;
    overflow-x: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

#loading-screen {
    position: fixed;
    z-index: 7;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 1;
    transition: 1s opacity;
}

#loading-screen.fade-out {
    opacity: 0;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: white;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    font-family: sans-serif;
    text-align: center
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 4px double transparent;
    border-top-color: white;
    -webkit-animmation: spin 3s linear infinit;
    animation: spin 3s linear infinite;
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: white;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

#loading-screen h1 {
    color: white;
    font-family: sans-serif;
    text-align: center;
}

#loadtext {
    position: fixed;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadefade 3s infinite
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fadefade {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #FF0000
}

#emoji {
    width: 65px;
    height: 65px;
    margin: 0 0 0 15px
}

.section
{
    display: flex;
    width: 100vh;
    height: 100vw;
    align-items: center;
    position: relative;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}

#aboutme, #projectbox {
    position: fixed;
    background: rgba(140, 140, 140, 0.5);
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 500px;
    visibility: hidden;
    animation: none;
    box-shadow: inset 0 0 7px #000000, 0 0 500px rgba(255, 255, 255, 0.3)
}

#aboutme2, #projectbox2 {
    /* text-align: center; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%
}

#aboutme2 {
    text-align: center;
}

#aboutme p {
    font-size: 29px;
    color: whitesmoke;
    padding: 15px 0;
    text-transform: lowercase;
}

#projectbox {
    font-size: 18px;
    color: whitesmoke;
    text-transform: lowercase;
    background: rgba(140, 140, 140, 0.85);
}

#projectitle {
    position: fixed;
    color: red;
    font-size: 24px;
    font-style: oblique;
    font-weight: 900;
    text-transform: lowercase;
    top: 14%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: none;
    opacity: 0;
}

#projectbox2 h3 {
    font-size: 32px;
    padding: 3px 0
}

#projectbox2 a:link, #projectbox2 a:visited {
    color: red;
}

#projectbox2 h4 {
    font-size: 14px;
    font-style: italic;
    /* padding: 5px 0 */
}

#projectbox2 p {
    padding: 5px 0
}

#but {
    position: fixed;
    z-index: 5;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
}

#libut {
    float: left;
    margin: 0 10px 0 0;
}

#litt {
    background-image: url("/static/img/linkedin.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    border:rgba(0, 0, 0, 0);
    animation: afadeIn 11s forwards
    /* transition: 0s filter */
}

#ghbut {
    float: right;
    margin: 0 0 0 10px;
}

#gitt {
    background-image: url("/static/img/github.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    border:rgba(0, 0, 0, 0);
    animation: afadeIn 11s forwards
    /* transition: 0s filter */
}

#litt:hover, #gitt:hover {
    cursor: pointer;
    filter: drop-shadow(0 0 13px rgba(255, 255, 255, .9));
    opacity: 1
    /* transition-delay: 0.07s */
}


#linktitle, #gittitle {
    font-size: 14px;
    text-align: center;
    padding: 4px 0 0 0;
    opacity: 0;
    font-family: 'Macondo', cursive;
    /* text-shadow: 0 0 2px white */
}

#litt:hover ~ #linktitle {
    animation: fadeIn 1s forwards;
    color: white;
}

#gitt:hover ~ #gittitle {
    animation: fadeIn 1s forwards;
    color: white;
}

#scrolldiv {
    position: fixed;
    bottom: 94px;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: afadeIn 12s forwards;
}

#scrolltext {
    color: rgba(255, 255, 255, .8);
    font-family: sans-serif;
    font-size: 14px;
}

@keyframes fadeIn {
    0% {opacity: 0}
    25% {opacity: 0}
    100% {opacity: 1}
}

@keyframes afadeIn {
    0% {opacity: 0}
    25% {opacity: 0}
    100% {opacity: .8}
}

@keyframes fadeOut {
    0% {opacity: 1}
    75% {opacity: 0}
    100% {opacity: 0}
}